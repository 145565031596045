<template>
  <div class="report_w">
    <!-- 汇报列表头部 -->
    <van-sticky :offset-top="0">
      <van-nav-bar :title="title" left-text left-arrow @click-left="onClickLeft" />
    </van-sticky>
    <div class="ava_info">
      <img width="54px" :src="userSite + avaPath" alt />
      <div class="position">
        <h4>{{reportParams.title}}</h4>
        <div>
          <span>{{position}}</span>
          <span>{{reportParams.create_time | fmtdate}}</span>
        </div>
      </div>
    </div>
    <div style="background:#fff;margin-top:12px">
      <div class="report_content">
        <h4>今天完成的工作</h4>
      </div>
      <van-field
        disabled
        v-model="reportParams.completed_work"
        rows="3"
        autosize
        type="textarea"
        placeholder="请输入"
      />
    </div>
    <div style="background:#fff;margin-top:12px">
      <div class="report_content">
        <h4>未完成的工作</h4>
      </div>
      <van-field
        disabled
        v-model="reportParams.incomplete_work"
        rows="3"
        autosize
        type="textarea"
        placeholder="请输入"
      />
    </div>
    <div style="background:#fff;margin-top:12px">
      <div class="report_content">
        <h4>明天的计划</h4>
      </div>
      <van-field
        disabled
        v-model="reportParams.plan"
        rows="3"
        autosize
        type="textarea"
        placeholder="请输入"
      />
    </div>
    <div style="background:#fff;margin-top:12px">
      <div class="report_content">
        <h4>工作心得</h4>
      </div>
      <van-field
        disabled
        v-model="reportParams.experience"
        rows="3"
        autosize
        type="textarea"
        placeholder="请输入"
      />
    </div>
    <div style="background:#fff;margin-top:12px">
      <div class="report_content">
        <h4>备注</h4>
      </div>
      <van-field
        disabled
        v-model="reportParams.remark"
        rows="3"
        autosize
        type="textarea"
        placeholder="请输入"
      />
    </div>
    <div style="background:#fff;margin-top:12px">
      <div class="report_content">
        <h4>抄送部门</h4>
      </div>
      <div class="select">
        <span>{{selectText}}</span>
      </div>
    </div>
    <div style="height:12px;background:#fff"></div>
    <div style="background:#fff">
      <div class="report_content">
        <h4>抄送人</h4>
      </div>
      <div class="select">
        <span>{{selectRole}}</span>
      </div>
    </div>
    <!-- 提交保存按钮 -->
    <div class="submit">
      <van-button icon="delete-o" class="sub_sure sub_detele" type="info" @click="del_submit()">删除汇报</van-button>
      <van-button icon="font-o" class="sub_sure" type="info" @click="info_submit()">编辑汇报</van-button>
    </div>

    <van-overlay :show="showOver" @click="showOver = false">
      <div class="wrapper">
        <div class="block">
          <div class="block_title">
            <h3>删除汇报</h3>
            <van-icon name="close" class="close" @click="showOver = false" />
          </div>
          <img
            style="width:80%;display:block;margin:30px auto"
            src="../../assets/images/report_del.png"
            alt
          />
          <span>确定删除汇报，无法恢复！</span>
          <van-button class="report_del" type="info" @click="report_del()">确定</van-button>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Toast } from 'vant'
import Site from '../../components/global.vue'
export default {
  data () {
    return {
      userSite: Site.userSite,
      avaPath: '',
      position: "",//职位回显
      showOver: false,
      selectText: '',//部门
      selectRole: '',//人员
      selectid: '',//部门id
      show: false,
      showRole: false,
      columns: [],
      columns_role: [],
      title: "", //汇报列表头部
      reportParams: {
        type: '',
        cc_id: '',
        completed_work: '',//今天完成的工作
        incomplete_work: '',//未完成的工作
        plan: "",//明天计划
        experience: "",//工作心得
        remark: "",//备注
      }
    }
  },
  created () {
    if (this.$route.query.item.id) {
      this.view_report()
      if (this.$route.query.item.type == 1) {
        this.title = '日报'
      } else if (this.$route.query.item.type == 2) {
        this.title = '周报'
      } else if (this.$route.query.item.type == 3) {
        this.title = '月报'
      }
    } else {
      this.title = this.$route.query.item.span
      this.reportParams.type = this.$route.query.item.type
    }
    this.avaPath = this.$route.query.item.avatar
    this.position = this.$route.query.item.position
  },
  methods: {
    view_report () {
      this.axios.get('/api/report/view', {
        params: {
          id: this.$route.query.item.id
        }
      }).then((res) => {
        console.log(res,888)
        this.reportParams = res.data
        this.selectText = res.data.organization_name
        this.selectRole = res.data.true_name
      })
    },
    onClickLeft () {
      this.$router.go(-1)
    },
    info_submit () {
      this.$router.push({ path: '/phone/report/add', query: { item: this.$route.query.item } })
    },
    del_submit () {
      console.log('删除汇报', this.$route.query.item)
      this.showOver = true
    },
    report_del () {
      this.axios.delete('/api/report/delete', { params: { id: this.$route.query.item.id } }).then(() => {
        Toast('删除成功')
        this.showOver = false
        this.$router.push('/phone/work_bench/report_list')
      })
    },
  },
}
</script>

<style scoped>
.report_w {
  width: 100%;
  height: 100%;
  background: #fff;
}
div/deep/.van-nav-bar .van-icon {
  color: #000;
}
.report_content {
  width: 92%;
  height: 50px;
  margin: 0 auto;
  overflow: hidden;
}
.report_content h4 {
  font-size: 20px;
  font-family: PingFangSC, PingFangSC-Semibold;
  font-weight: 600;
  color: #000000;
  margin-top: 20px;
}
.select {
  color: #b9bec2;
  width: 92%;
  margin: 0 auto;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.submit {
  width: 100%;
  background: #fff;
  text-align: center;
  display: flex;
  justify-content: space-between;
}
div/deep/.sub_sure {
  width: 40%;
  margin: 20px auto;
  border-radius: 24px;
}
div/deep/.sub_detele {
  background: #eff4f8;
  color: #000;
  font-weight: bold;
  border-color: #eff4f8;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 80%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 30px;
  border-radius: 20px;
  text-align: center;
}
.ava_info {
  width: 92%;
  margin: 12px auto 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 14px;
}
.ava_info img {
  border-radius: 50%;
}
.position {
  margin-left: 12px;
}
.position span {
  margin-right: 12px;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #a1a1a1;
}
.position h4 {
  font-size: 20px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  color: #000000;
}
.block_title {
  display: flex;
  justify-content: space-between;
}
.block_title h3 {
  font-size: 20px;
  font-family: PingFangSC, PingFangSC-Semibold;
  font-weight: 600;
  color: #000000;
  margin: 0 auto;
}
.block_title .close {
  font-size: 24px;
}
.report_del {
  width: 100%;
  margin-top: 30px;
  border-radius: 24px;
}
</style>